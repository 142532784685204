import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import {withCookies} from "react-cookie";

import {
  showNav,
  hideNav,
  closeModal,
  loadGiftsStore,
  loadPaymentPrices,
  loadCurrentUser,
} from "common/actions";
import {api} from "common/middleware/api";
import {
  sendPacket,
  USER_OFFLINE,
  USER_ONLINE,
} from "common/middleware/socketio";
import {
  isSSR,
  getUser,
  isUserDisabled,
  getLSKey,
  LS_KEYS,
} from "common/helpers";

import Aside from "components/Aside";
import AsideFooter from "components/AsideFooter";
import Auth from "components/Auth";
import BadgeUpdater from "components/BadgeUpdater";
import Footer from "components/Footer";
import LayoutHelmet from "./components/Helmet";
import Menu from "components/Menu";
import ModalAlbum from "components/ModalAlbum";
import ModalAuth from "components/ModalAuth";
import ModalPayProxy from "components/ModalPayProxy";
import ModalTicketCooperation from "components/ModalTicketCooperation";
import ModalTicketSupport from "components/ModalTicketSupport";
import Notifier from "components/Notifier";
import PartnerSochi from "components/PartnerSochi";
import Topnav from "components/Topnav";
import UserPanel from "components/UserPanel";
import UsersInteresting from "components/UsersInteresting";
import UsersRecommended from "components/UsersRecommended";

import "./reset.css";
import "./style.css";

const LayoutContext = React.createContext();

const MODAL_ALBUM = "MODAL_ALBUM";
const MODAL_AUTH = "MODAL_AUTH";
const MODAL_COOPERATION = "MODAL_COOPERATION";
const MODAL_PAY = "MODAL_PAY";
const MODAL_SUPPORT = "MODAL_SUPPORT";

class Layout extends Component {
  constructor(props) {
    super(props);

    this.goOffline = this.goOffline.bind(this);
    this.goOnline = this.goOnline.bind(this);
    this.toggleNav = this.toggleNav.bind(this);

    // this.observer = null;
    // this.isObserverConnected = false;

    this.state = {
      hasError: false,
      // interactionBlocker: 0,
    };
  }

  componentDidMount() {
    this.makeRedirects();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.workingForms.length > 0) {
      return false;
    }

    return true;
  }

  componentDidMount() {
    // Maybe someday...
    // this.registerInteractionBlocker();

    // if (this.props.currentUser.role < 10) {
    //   this.connectInteractionBlocker();
    // }

    this.__TEMP_migrateV1Auth();
    this.props.loadGiftsStore();
    this.props.loadPaymentPrices();

    window.addEventListener("beforeunload", this.goOffline, false);

    if (process.env.NODE_ENV === "production") {
      window.addEventListener("blur", this.goOffline, false);
      window.addEventListener("focus", this.goOnline, false);
    }
  }

  componentDidUpdate(prevProps) {
    const {currentUser, session, loadCurrentUser} = this.props;

    // const isLocationChanged = location.key !== prevProps.location.key;

    this.makeRedirects();

    if (
      session &&
      session.user &&
      !(currentUser && currentUser.id) &&
      !isSSR() &&
      !window.currentUserLoaded
    ) {
      window.currentUserLoaded = true;
      loadCurrentUser();
    }

    // if (isLocationChanged) {
    //   this.blockInteractions();
    // }
    // if (currentUser.role >= 10 && prevProps.currentUser.role < 10 && this.isObserverConnected) {
    //   this.disconnectInteractionBlocker();
    // }
    // if (currentUser.role < 10 && prevProps.currentUser.role >= 10 && !this.isObserverConnected) {
    //   this.connectInteractionBlocker();
    // }
  }

  componentWillUnmount() {
    this.goOffline();

    if (process.env.NODE_ENV === "production") {
      window.removeEventListener("blur", this.goOffline, false);
      window.removeEventListener("focus", this.goOnline, false);
    }
  }

  static getDerivedStateFromError() {
    if (process.env.NODE_ENV === "production") {
      return {
        hasError: true,
      };
    }

    return null;
  }

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV === "production") {
      const body = new FormData();
      const debug = [error, info.componentStack];

      if (!isSSR()) {
        debug.push(window.location.href);
      }

      body.append("error", debug.join(" "));
      api.post("debug/error", body);
    }
  }

  __TEMP_migrateV1Auth() {
    const {session, cookies} = this.props;
    const pathname = "/social/site";

    if (
      process.env.NODE_ENV === "development" ||
      isSSR() ||
      session ||
      !cookies.get("PHPSESSID") ||
      cookies.get(getLSKey(LS_KEYS.MIGRATED)) ||
      window.location.pathname === pathname
    ) {
      return;
    }

    window.location.href = pathname;
  }

  // registerInteractionBlocker() {
  //   if (isSSR() || !('MutationObserver' in window)) {
  //     return;
  //   }

  //   this.observer = new MutationObserver(mutationsList => {
  //     for (const mutation of mutationsList) {
  //       if (mutation.attributeName === 'style') {
  //         this.blockInteractions();
  //         break;
  //       }
  //     }
  //   });
  // }

  // connectInteractionBlocker() {
  //   if (!this.observer) {
  //     return;
  //   }

  //   this.observer.observe(document.documentElement, { attributes: true });
  //   this.isObserverConnected = true;
  // }

  // disconnectInteractionBlocker() {
  //   if (!this.observer) {
  //     return;
  //   }

  //   this.observer.disconnect();
  //   this.isObserverConnected = false;
  // }

  // blockInteractions() {
  //   const { isEditPage, currentUser } = this.props;
  //   const isBlocked = document.documentElement.style.pointerEvents === 'none';
  //   const shouldBlock = currentUser.role < 10 && !isEditPage;

  //   this.setState(({ interactionBlocker }) => {
  //     if (!isBlocked && shouldBlock) {
  //       return {
  //         interactionBlocker: interactionBlocker + 1,
  //       };
  //     }
  //     if (isBlocked && !shouldBlock) {
  //       return {
  //         interactionBlocker: 0,
  //       };
  //     }

  //     return null;
  //   });
  // }

  goOffline() {
    const {id: userId} = this.props.currentUser;

    if (!userId) {
      return;
    }

    sendPacket(USER_OFFLINE, {
      userId,
    });
  }

  goOnline() {
    const {id: userId} = this.props.currentUser;

    if (!userId) {
      return;
    }

    sendPacket(USER_ONLINE, {
      userId,
    });
  }

  makeRedirects() {
    const {location, history, session, currentUser} = this.props;

    if (!isSSR() && session) {
      if (isUserDisabled(currentUser) && location.pathname !== "/edit") {
        history.replace("/edit");
      }
      if (location.pathname === "/") {
        history.replace("/welcome");
      }
    }
  }

  toggleNav() {
    const {navIsVisible, hideNav, showNav} = this.props;

    navIsVisible ? hideNav() : showNav();
  }

  get asideProps() {
    const {isLanding, navIsVisible} = this.props;

    if (isLanding) {
      return {};
    }

    return {
      navIsVisible,
    };
  }

  render() {
    const {
      modal,
      navIsVisible,
      withFooter,
      withRecommended,
      shortAsideFooter,
      session,
      location,
      isLanding,
      children,
      currentUser,
      isUserInInteresting,
      pageType,
      isAMP,
    } = this.props;
    const {closeModal} = this.props;
    const {interactionBlocker, hasError} = this.state;

    const isAuthVisible =
      modal.type !== MODAL_AUTH && (modal.type !== MODAL_PAY || !!session);

    // after.js не поддерживает разные лейауты для разных роутов,
    // поэтому изощряемся с isLanding,
    // чтобы и лендинг, и обычные страницы работали нормально в одном.
    // Условия для рендера children (типа isLanding ? <Landing> : <CommonLayout>) писать нельзя,
    // т.к. в этом случае вызов метода "getInitialProps" для роута "/welcome" не происходит,
    // если перейти на него с роута "/" (условие срабатывает позже смены роута,
    // а этот метод должен сработать ДО).
    //
    // Возможно, это однажды исправят.
    // https://github.com/jaredpalmer/after.js/pull/173
    // https://github.com/jaredpalmer/after.js/pull/208

    const isNewYearTime = false;

    return (
      <div
        className={classNames(
          "layout",
          {"-landing": isLanding},
          {"-default": !isLanding}
        )}
      >
        <LayoutHelmet
          navIsVisible={navIsVisible}
          isLanding={isLanding}
          shouldBlockInteractions={interactionBlocker > 0}
          pageType={pageType}
          key={interactionBlocker}
        />
        <LayoutContext.Provider value={this.props}>
          {hasError ? (
            <div className="layout__error">
              <img src="/images/logo.svg" alt="LinkYou" />
              <h2>Ой!</h2>
              <p>
                Произошла непредвиденная ошибка. <br />
                Мы уже работаем над её исправлением.
              </p>
              <p>
                А пока что попробуйте <a href="/">перейти на главную</a>.
              </p>
              <img
                src="https://media2.giphy.com/media/JIX9t2j0ZTN9S/giphy.gif?cid=790b76115d234e1e68524133517f594f&rid=giphy.gif"
                alt=""
              />
            </div>
          ) : (
            <React.Fragment>
              <div
                className={classNames("layout__fader", {
                  "-active": navIsVisible,
                })}
                onClick={hideNav}
              />
              {!isLanding && (
                <Topnav
                  navIsVisible={navIsVisible}
                  toggleNav={this.toggleNav}
                  isAMP={isAMP}
                />
              )}
              <div className="layout__body">
                <div className={isLanding ? "" : "container"}>
                  <div className="layout__body-content">
                    {!isAMP && (
                      <div className="layout__body-aside">
                        <Aside {...this.asideProps}>
                          {isLanding ? (
                            <React.Fragment>
                              {!isNewYearTime && (
                                <div className="layout__body-aside-logo">
                                  <img src="/images/logo.svg" alt="LinkYou" />
                                  <span className="layout__body-aside-logo-slogan">
                                    Знакомьтесь по профессии
                                  </span>
                                </div>
                              )}
                              {isNewYearTime && (
                                <div className="layout__body-aside-logo -new-year">
                                  <img src="/images/logo.svg" alt="LinkYou" />
                                  <span>с новым счастьем!</span>
                                  <div className="layout__body-aside-logo-tree" />
                                </div>
                              )}
                              <div className="layout__body-aside-auth">
                                {isAuthVisible && <Auth />}
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {isAuthVisible && <UserPanel />}
                              <Menu />
                            </React.Fragment>
                          )}
                          <div className="layout__body-aside-interesting">
                            <UsersInteresting
                              allowBecome={!isLanding}
                              key={`user=${
                                currentUser.id || 0
                              }_paid=${isUserInInteresting}`}
                            />
                          </div>
                          {isLanding ? (
                            <AsideFooter isShort={shortAsideFooter} />
                          ) : (
                            <PartnerSochi />
                          )}
                        </Aside>
                      </div>
                    )}
                    <main className="layout__body-main">
                      {children}
                      {!isLanding && withFooter && <Footer isAMP={isAMP} />}
                    </main>
                    {!isLanding && withRecommended && <UsersRecommended />}
                  </div>
                </div>
              </div>
              {!isSSR() && <Notifier location={location} />}
              <BadgeUpdater location={location} />
              <ModalAlbum
                isOpen={modal.type === MODAL_ALBUM}
                handleClose={closeModal}
              />
              <ModalTicketSupport
                isOpen={modal.type === MODAL_SUPPORT}
                handleClose={closeModal}
              />
              <ModalTicketCooperation
                isOpen={modal.type === MODAL_COOPERATION}
                handleClose={closeModal}
              />
              <ModalAuth
                isOpen={modal.type === MODAL_AUTH}
                handleClose={closeModal}
                view={modal.payload.view}
              />
              {modal.type === MODAL_PAY && (
                <ModalPayProxy {...modal.payload} isOpen />
              )}
            </React.Fragment>
          )}
        </LayoutContext.Provider>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {
    session,
    modal,
    layoutSettings: {
      shortAsideFooter,
      nav: navIsVisible,
      footer: withFooter,
      recommended: withRecommended,
    },
  } = state;

  let path = isSSR()
    ? ownProps.children.props.location
    : ownProps.location.pathname;
  const pathQueryIndex = path.indexOf("?");

  if (pathQueryIndex >= 0) {
    path = path.substr(0, pathQueryIndex);
  }

  return {
    session,
    modal,
    shortAsideFooter,
    navIsVisible,
    withFooter,
    withRecommended,
    isLanding: (path === "/" && !session) || path === "/amp/preview",
    isAMP: path.includes("/amp"),
    isEditPage: path === "/edit",
    currentUser: getUser(state, (state.session || {}).user),
    workingForms: state.workingForms,
    giftsStoreRequestState: state.states.giftsStore || {},
    pricesRequestState: state.states.loadPaymentPrices || {},
    isUserInInteresting: !!(state.data.usersInteresting || {}).userPaidData,
    pageType: path.split("/")[1],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showNav,
      hideNav,
      closeModal,
      loadGiftsStore,
      loadPaymentPrices,
      loadCurrentUser,
    },
    dispatch
  );
}

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(Layout)
);
export {LayoutContext};
