import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";

import {getUser, makeSearchLink, makeHomeLink} from "common/helpers";

import Icon from "components/Icon";
import Menu from "components/Menu";
import Link from "components/Link";
// import LazyLoadImage from 'components/LazyLoadImage';

import "./style.css";

class Topnav extends Component {
  static propTypes = {
    navIsVisible: PropTypes.bool.isRequired,
    toggleNav: PropTypes.func.isRequired,
  };

  static defaultProps = {
    navIsVisible: false,
  };

  render() {
    const {navIsVisible, toggleNav, currentUser, isAMP} = this.props;

    const isNewYearTime = false;

    return (
      <div className={classNames("topnav", {"-with-nav": navIsVisible})}>
        <div className="container">
          <div className="topnav__burger">
            {!isAMP && (
              <button
                type="button"
                className="topnav__burger-handler"
                onClick={toggleNav}
              >
                <i />
                <i />
                <i />
              </button>
            )}
            {isAMP && (
              <a href="https://linkyou.ru/">
                <button
                  type="button"
                  className="topnav__burger-handler"
                  onClick={toggleNav}
                >
                  <i />
                  <i />
                  <i />
                </button>
              </a>
            )}
          </div>
          <div className="topnav__search">
            <Link
              to={makeSearchLink(currentUser)}
              className="topnav__search-handler"
            >
              <Icon glyph="search-topnav" />
            </Link>
          </div>
          {isNewYearTime && (
            <div className="topnav__logo -new-year">
              <Link to={makeHomeLink(currentUser)}>
                <img src="/images/logo.svg" alt="LinkYou" />
                <span>с новым счастьем!</span>
                <div className="topnav__logo-tree"></div>
              </Link>
            </div>
          )}
          {!isNewYearTime && (
            <div className="topnav__logo">
              <Link to={makeHomeLink(currentUser)}>
                <img src="/images/logo.svg" alt="LinkYou" />
              </Link>
              <div className="topnav__logo-slogan">
                Знакомьтесь по профессии
              </div>
            </div>
          )}
          {/* {isAMP && (
            <div className="topnav__logo">
              <Link to={makeHomeLink(currentUser)}>
                {isAMP && (
                  <amp-img
                    src="/images/logo.svg"
                    alt="LinkYou"
                    layout="responsive"
                    width="90"
                    height="16"
                  ></amp-img>
                )}
                {!isAMP && <img src="/images/logo.svg" alt="LinkYou" />}
              </Link>
            </div>
          )} */}
          <Menu />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: getUser(state, (state.session || {}).user),
  };
}

export default connect(mapStateToProps)(Topnav);
